import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IUserResponse, IUser, IUserRegister, IProvinceResponse, 
         IProvincia, ICondFiscalVtas, ICondFiscalVtasResponse, 
         IPaisResponse, IPais, ILocalidad, ILocalidadResponse, 
         ITipoDoc, ITipoDocResponse, IWebCondFiscalTipoDocResponse,
         IUserRegisterDatos,IUserDataExist} from 'src/app/interfaces/user.interface';
import { IFileDownload, IProduct } from 'src/app/interfaces/product.interface';
import { IErrorData, IError } from 'src/app/interfaces/error.interface';
import { API } from '../api.enum';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { CartService } from '../cart/cart.service';
import { FavoritesService } from '../favorites/favorites.service';
import { StorageService } from '../../services/storage/storage.service';
import { ProductsService } from '../products/products.service';
import { ICartParam } from 'src/app/interfaces/cart.interface';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public isChecked: boolean = false;
  public data: IUser | null = null;
  public isLoggedIn: boolean = false;
  public token: string = '';
  public provincias: IProvincia[] = [];
  public condiciones: ICondFiscalVtas[] = [];
  public paises: IPais[] = [];
  public localidades: ILocalidad[] = [];
  public tiposDoc: ITipoDoc[] = [];
  cantArtMax: any;

  public noCrearNuevoUSer : boolean = true;

  //
  public titleLogin: string = '';
  public routeBeforeLogin: string = '/';

  //
  public idEnvase: number = null;
  public isCartStatus: boolean = false;
  public isMedioDePagoEnabled: boolean = false;
  /**
   * UserService
   */
  constructor(
    private router: Router,
    private http: HttpClient,
    private cartService: CartService,
    private favoriteService: FavoritesService,
    private storageService: StorageService,
    private productService: ProductsService
  ) {

  }

  /**
   * login
   * @param user
   * @param password 
   */
  public login(user: string, password: string): Observable<IUserResponse> {
    return this.http.post<IUserResponse>(
      API.BASE + API.LOGIN,
      {
        User: user,
        Password: password
      }
    );
  }

  public setEmailAndRecovery(user: string, realEmail: string, password: string): Observable<IUserRegister> {
    return this.http.post<IUserRegister>(
      API.BASE + API.SET_EMAIL,
      {
        Usuario: user,
        EMail: realEmail,
        Password: password
      }
    )
  }

  public existUser(user: string): Observable<IUserRegister> {
    return this.http.post<IUserRegister>(
      API.BASE + API.EXIST_USER,
      {
        Usuario: user
      }
    )
  }

  public datosUser(user: string, nrodocumento: string): Observable<IUserDataExist> {
    return this.http.post<IUserDataExist>(
      API.BASE + API.DATOS_USER,
      {
        email: user,
        nrodocumento: nrodocumento
      }
    )
  }

  /**
   * checkUser
   */
  public checkUser(): void {
    this.storageService.getItem('session', null)
      .then((response) => {
        //valido que el token no este vencido
        var dateSub = Number(response.data.Expires.toString().substring(6, 19));
        var dateNow = Date.now();

        if (dateNow > dateSub) {

          this.favoriteService.clearList();
          this.cartService.clearList();
          this.setData(null, false);
          this.router.navigate(['/']);
        } else {
          const data: IUserResponse | null = response.data;
          if (data !== null && data.User && data.Token) {
            this.data = data.User;
            this.token = data.Token;
            this.isLoggedIn = true;
            this.isCartStatus = (data) ? data.CartEnabled : false;
            this.isMedioDePagoEnabled = (data) ? data.MedioDePagoEnabled : false;
            this.cartService.loadCart();
            this.favoriteService.loadFavorites();
            this.cantArtMax = data.CantArtMax;
          }
          this.isChecked = true;
        }
      })
      .catch((error) => {
        this.isChecked = true;
      });
  }

  public getCodCliGeneric(): Observable<any> {
    return this.http.post<any>(API.BASE + API.CODCLIGENERIC_GET, {});
  }
  // CODCLIGENERIC_GET

  public getOpcionesPagoEnabled(UserId: number): Promise<any> {
    return new Promise((resolve) => {
      if (UserId > 0)
        return this.http.post<any>(API.BASE + API.OPCIONESPAGO_LIST, { UserId });
      else
        return null;
    });
  }
  public validate(key: string): Observable<IErrorData> {
    return this.http.post<IErrorData>(
      API.BASE + API.VALIDATEKEY,
      {
        ActivationKey: key,
      }
    );
  }


  /*
  *
  Activate 
  */
  public activate(key: string): Observable<IErrorData> {
    return this.http.post<IErrorData>(
      API.BASE + API.ACTIVATE,
      {
        ActivationKey: key,
      }
    );
  }

  /**
   * ResetPassword
   *
   */
  public resetPassword(email: string): Observable<IUserRegister> {
    return this.http.post<IUserRegister>(
      API.BASE + API.PASSWORD_RESET,
      {
        Email: email,
      }
    );
  }

  /**
   * changePassword 
   * 
   * */
  public changePassword(key: string, password: string): Observable<IErrorData> {
    return this.http.post<IErrorData>(
      API.BASE + API.PASSWORD_CHANGE,
      {
        ActivationKey: key,
        Password: password
      }
    );
  }

  public editProfile(
    businessName: string,
    email: string,
    //password: string,
    nroDocumento: string,
    cuit: string,
    condFiscID: number,
    codPais: string,
    codArea: string,
    nroTel: string,
    calle: string,
    puerta: string,
    piso: string,
    depto: string,
    paisID: number,
    provinciaID: number,
    localidadID: number,
    localidad: string,
    codPostal: string,
    dirEntregas: any[],
    tipoDocID: number
  ): Observable<IErrorData> {
    return this.http.post<IErrorData>(API.BASE + API.PROFILE_EDIT, {
      FullName: businessName,
      UserName: email,
      //Password: password,
      NroDocumento: nroDocumento,
      Cuit: cuit,
      CondFiscalVtasID: condFiscID,
      CodPais: codPais,
      CodArea: codArea,
      NroTel: nroTel,
      Calle: calle,
      Puerta: puerta,
      Piso: piso,
      Depto: depto,
      PaisID: paisID,
      ProvinciaID: provinciaID,
      LocalidadID: localidadID,
      Localidad: localidad,
      CodPostal: codPostal,
      DirEntregas: dirEntregas,
      TipoDocID: tipoDocID
    });
  }

  /**
   * 
   * @param cuit 
   * @param password 
   * @param email 
   * @param businessName 
   * @param phone 
   * @param street 
   * @param door 
   * @param floor 
   * @param dept 
   * @param location 
   * @param provinceID 
   * @param postalCode 
   * @param isCliente 
   */
  // public register(
  //   cuit: string,
  //   password: string,
  //   email: string,
  //   businessName: string,
  //   codPais: string,
  //   codArea: string,
  //   nroTel: string,
  //   street: string,
  //   door: number,
  //   floor: string,
  //   dept: string,
  //   location: string,
  //   provinceID: number,
  //   postalCode: string,
  //   isCliente: boolean,
  //   esConsumidorFinal: boolean,
  //   condFiscalVtas: number,
  //   paisID: number,
  //   localidadID: number,
  //   domEntrega: boolean,
  //   dirEntregas: [],
  //   dni: string,
  //   tipoDocID: number,
  //   nroDoc: string
  // )
  public register(

    cuit: string,
    password: string,
    email: string,
    businessName: string,

    dni: string,
    tipoDocID: number,

    street: string,
    nroDoc: string,
    provinciaID: number,
    paisID: number,
    localidadID: number,
    localidadDesc: string
  )
    
    : Observable<IUserRegister> {
    return this.http.post<IUserRegister>(
      API.BASE + API.REGISTER,
      
      {
        // Name: name,
        CUIT: cuit,
        RazonSocial: businessName,
        Calle: street,
        Localidad: location,
        Email: email,
        Password: password,
        Dni: dni,
        TipoDocID: tipoDocID,
        NroDocumento: nroDoc,
        ProvinciaID: provinciaID,
        PaisID: paisID,
        LocalidadID: localidadID,
        LocalidadDesc: localidadDesc
      }
    );
  }

  public getProvinces(): Observable<IProvincia[]> {
    return new Observable((observer) => {
      this.http.post<IProvinceResponse>(API.BASE + API.PROVINCE_LISTA, {}).subscribe((asyncData: IProvinceResponse) => {
        observer.next(asyncData.Provincias);
        observer.complete();
      });
    });
  }

  public getCondFiscalVtas(): Observable<ICondFiscalVtas[]> {
    return new Observable((observer) => {
      this.http.post<ICondFiscalVtasResponse>(API.BASE + API.CONDFISCALVTAS_LIST, {}).subscribe((asyncData: ICondFiscalVtasResponse) => {
        observer.next(asyncData.CondicionesFiscVtas);
        observer.complete();
      })
    });
  }

  public getTiposDoc(): Observable<ITipoDoc[]> {
    return new Observable((observer) => {
      this.http.post<ITipoDocResponse>(API.BASE + API.TIPODOC_LIST, {}).subscribe((asyncData: ITipoDocResponse) => {
        observer.next(asyncData.TiposDoc);
        observer.complete();
      })
    });
  }

  public getWebCondFiscalTipoDoc(): any {
    return new Observable((observer) => {
      this.http.post<IWebCondFiscalTipoDocResponse>(API.BASE + API.WEBCONDFISCALTIPODOC_LIST, {}).subscribe((asyncData: IWebCondFiscalTipoDocResponse) => {
        observer.next(asyncData.WebCondFiscalTipoDoc);
        observer.complete();
      })
    });
  }

  public getPaises(): Observable<IPais[]> {
    return new Observable((observer) => {
      this.http.post<IPaisResponse>(API.BASE + API.PAISES_LIST, {}).subscribe((asyncData: IPaisResponse) => {
        observer.next(asyncData.Paises);
        observer.complete();
      })
    });
  }

  public getLocalidades(provinciaID: number, paisID: number): Observable<ILocalidad[]> {
    return new Observable((observer) => {
      this.http.post<ILocalidadResponse>(API.BASE + API.LOCALIDADES_LIST, {
        PaisID: paisID,
        ProvinciaId: provinciaID
      }).subscribe((asyncData: ILocalidadResponse) => {
        observer.next(asyncData.Localidades);
        observer.complete();
      })
    });
  }

  public checkUserBeforeAction(title?: string): boolean {
    if (this.isLoggedIn === true && this.token !== '') {
      return true;
    } else {
      this.titleLogin = title;
      this.routeBeforeLogin = this.router.url; // Save route before login
      this.router.navigate(['user/login']);
      return false;
    }
  }

  public logout(): void {
    this.favoriteService.clearList();
    this.cartService.clearList();
    this.setData(null, false);
    this.router.navigate(['/']);
    setTimeout(() => { //window.location.reload(); 
    }, 200);
  }

  public setData(data: IUserResponse | null, isLogged: boolean): void {
    this.data = (data) ? data.User : null;
    this.token = (data) ? data.Token : '';
    this.isLoggedIn = (data) ? isLogged : false;
    this.isMedioDePagoEnabled = (data) ? data.MedioDePagoEnabled : false;
    this.isCartStatus = (data) ? data.CartEnabled : false;
    this.storageService.setItem('session', data);
    if (data != null && this.isLoggedIn) {
      this.cartService.loadCart();
      this.favoriteService.loadFavorites();
      this.cantArtMax = data.CantArtMax;
    }
  }


  public getName(): string {
    return (this.data) ? this.data.FullName : '';
  }

  public getCustomerId(): number {
    return (this.data) ? this.data.CustomerId : 0;
  }

  public getConsumidorFinal(): boolean {
    return (this.data) ? this.data.EsConsumidorFinal : false;
  }

  public getDescuentoCliente(): number {
    return (this.data) ? this.data.DescuentoCliente : null;
  }

  public getValorMinimoEnvio(): number {
    return (this.data) ? this.data.ValorMinimoEnvio : null;
  }

  public getValorMinimoSucursal(): number {
    return (this.data) ? this.data.ValorMinimoSucursal : null;
  }

  public getCondicionFiscal(): number {
    return (this.data) ? this.data.CondFiscalVtasId : 0;
  }


  public getUserId(): number {
    return (this.data) ? this.data.UserId : 0;
  }

  public getProfileId(): number {
    return (this.data) ? this.data.ProfileId : 0;
  }

  public getUserName(): string {
    return (this.data) ? this.data.UserName : '';
  }

  public getCantArtMax(): number {
    return this.cantArtMax;
  }

  public getRouteAfterLogin(): string {
    const route: string = this.routeBeforeLogin;
    this.routeBeforeLogin = '/';
    return route;
  }


  public contact(
    name: string,
    email: string,
    provinceID: number,
    province: string,
    paisID: number,
    localidadID: number,
    Localidades: string,
    city: string,
    phone: string,
    message: string
  )
    : Observable<IErrorData> {
    return this.http.post<IErrorData>(
      API.BASE + API.CONTACT,
      {
        Name: name,
        Email: email,
        ProvinceID: provinceID,
        Province: province,
        PaisID: paisID,
        LocalidadID: localidadID,
        Localidades: Localidades,
        City: city,
        Phone: phone,
        Message: message
      }
    )
  }

  public sendinblue(
    email: string,
    attributes: {
      FIRSTNAME: string
    }
  ): Observable<IErrorData> {
    return this.http.post<IErrorData>(
      API.BASE + API.LANDING_SENDINBLUE,
      {
        email: email,
        attributes: attributes
      }
    );
  }
  /**
   * file download 
   * */

  //TODO falta parametro?
  public fileDownload(): Observable<IFileDownload> {
    return this.http.post<IFileDownload>(
      API.BASE + API.FILE_DOWNLOAD, {

    }
    );
  }

  public loginStatus(): Observable<any> {
    return this.http.post(API.BASE + API.LOGIN_STATUS, {});
  }

  public getCondFiscalVtasIds(): Observable<any> {
    return this.http.post(API.BASE + API.ACCOUNT_CONDFISVALUE, {});
  }

  public getCondFiscalVtasIdsIva(): Observable<any> {
    return this.http.post(API.BASE + API.ACCOUNT_CONDFISVALUEIVA, {});
  }

  /*public  UserHistoryDate
  (
      FechaDesde: Date,
      FechaHasta:Date
  )*/
  public getHistorial(desde: Date, hasta: Date): Observable<any> {



    return this.http.post(API.BASE + API.HISTORY_LIST, {
      FechaDesde: desde,
      FechaHasta: hasta
    });
  }

  public historialFileDownload(Nombre: String): Observable<any> {
    return this.http.post(API.BASE + API.HISTORY_DOWNLOAD, { Nombre });
  }

  public historialCopy(ComprobanteId: number): Observable<any> {
    return this.http.post<any>(API.BASE + API.HISTORY_COPY, { ComprobanteId });
  }


}
