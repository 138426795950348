import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { LoadingScreenService } from '../loading-screen/loading-screen.service';
import { UserService } from '../user/user.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  /**
   * AuthInterceptor
   * @param user
   */
  constructor(private userS: UserService, private router: Router,private loadingScreenService : LoadingScreenService) {
  }

  /**
   * intercept
   * @param request 
   * @param next 
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Headers
    //this.userS.checkUser();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.userS.token
    });

    // Return new cloned request
    const cloned = request.clone({ headers });

    // Error handle

    return next.handle(cloned)
    .pipe(
      catchError(err => {
        this.loadingScreenService.hide()
        if (err instanceof HttpErrorResponse) {
          let errorMsg = '';

          if(err.error instanceof ErrorEvent){            
            console.log("this is a cliente side error");
            errorMsg = 'Error: ' + err.error.message;
          } else {
            console.log("this is a server side error");
            errorMsg = 'Error code: ' + err.error.status + ' Message: ' + err.error.message;
            // this.userS.logout();
           // this.router.navigate(['/errors']);
          }

          console.log(errorMsg);

          // if (err.status === 401 || err.status === 403) {
          //   this.userS.logout();
          //   this.router.navigate(['/user/login']);
          // }
          

          // return the error back to the caller
          return throwError(err);
        }
      })
    );    
    // .pipe(
    //   map((event: HttpEvent<any>) => {
        
    //     console.log("event log");
    //     console.log(event);
    //     if (event instanceof HttpResponse) {
    //       switch (event.status) {
    //         // Invalid access token
    //         case 401:
    //           this.router.navigate(['/user/login']);
    //         // case 401:
    //         //   alert('invalid access token');
    //         //   break;
    //         // api not found
    //         case 404:
    //           alert('api not found');
    //           break;
    //         // connection error
    //         case 500:
    //           alert('connection error');
    //           break;
    //         // Check if has validation errors
    //         case 200:
    //           // Map API legacy errors
    //           if (event.body.Errores || event.body.Messages) {
    //             event.body.Errors = {
    //               HasErrors: true,
    //               ErrorList: [{
    //                 Message: 'Se produjo un error en la coneexión',
    //                 Source: null
    //               }]
    //             };
    //           }
    //           // TODO: Check this whit Pablo
    //           else if (event.body.HasErrors) {
    //             event.body.Errors = {
    //               HasErrors: true,
    //               ErrorList: event.body.ErrorList
    //             };
    //           }
    //           // Show error
    //           if (event.body.Errors && event.body.Errors.HasErrors === true) {
    //             // alert(event.body.Errors.ErrorList[0].Message);
    //           }
    //           break;
    //       }
    //     }
    //     return event;
    //   })
    // );
  }
}