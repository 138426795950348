import { Component } from '@angular/core';
import { UserService } from './services/user/user.service';
import { LoadingScreenService } from './services/loading-screen/loading-screen.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(private userS: UserService,
    private loadingScreenService : LoadingScreenService
    ) {

    this.userS.checkUser()
  }

    title = 'e-commerce';
    isLoading$ : boolean = true;


    ngOnInit(): void {
      this.loadingScreenService.isLoading$.subscribe(res=>{
        this.isLoading$ = res
      })
    }

    ngAfterViewInit(){
      let timer = setTimeout(()=>{
        this.loadingScreenService.hideVanilla()
        clearTimeout(timer)
      },25000)
    }
}
