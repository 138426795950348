import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { StorageService } from '../storage/storage.service';
import { HttpClient } from '@angular/common/http';
import { Descuento, IGetDiscountResponse, IProduct, IProductsResponse, ReferenceGroupResponse } from 'src/app/interfaces/product.interface';
import { ICartResponse, ICart, IProject, ICartValidateCallback, ICartParam } from 'src/app/interfaces/cart.interface';
import { IErrorData } from 'src/app/interfaces/error.interface';
import { IUserRegisterDatos } from 'src/app/interfaces/user.interface';
import { API } from '../api.enum';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  public cart: any = [];
  public notIncludesContainer: any = [];

  public enabled: boolean;
  private list = new BehaviorSubject<any[]>([]);
  list$ = this.list.asObservable();

  private Total: number;
  private TotalBehavior = new BehaviorSubject<number>(0);
  public EmailVerificar = new BehaviorSubject<string>('');
  public NroDocumentoVerificar = new BehaviorSubject<any>('');
  Total$ = this.TotalBehavior.asObservable();

  private CantTotal: number;
  private CantBehavior = new BehaviorSubject<number>(0);
  Cant$ = this.CantBehavior.asObservable();

  private paso: number;
  private pasoBehavior = new BehaviorSubject<number>(0);
  paso$ = this.pasoBehavior.asObservable();

  public RetiroId: number;
  public TipoDeRetiroId: number;
  public Observaciones: string = '';
  public OpcionesPagoId: number;
  public CodCompId: number;
  public Sucursal: number;
  public NroComp: number;
  public SucursalPed: number;
  public CodCompIdPed: number;
  public NroCompPed: number;
  public PreferenceId: string;
  public PaymentId: number;
  public TotalMP: number;
  public TotalImpuestos: number;
  public SubTotal: number;

  public TransporteId: number;
  public TransporteDesc: string;
  public OpcionDePagoDesc: string;
  public DireccionEntrega: string;

  public CuponId: number;
  public Porcentaje: number;

  public mensajeError: string;
  public hasPendingMP = new BehaviorSubject<boolean>(false);

  public Proyecto: IProject = {
    name: "",
    fechaLimite: "",
    fechaDecision: "",
    fechaProvision: "",
    nameFinal: "",
    nameContacto: "",
    emailCliFinal: "",
    presupuestoId: 0,
    contratacionId: 0,
    tipoProyectoId: 0,
    categoriaId: 0,
    zonaId: 0,
    nameFirma: "",
    nameResp: "",
    emailResp: "",
    phoneResp: "",
    phoneCliFinal: "",
    despProyecto: "",
    equipamiento: "",
    competidores: "",
    observaciones: "",
    nameDist: "",
    nameDistResp: "",
    emailDistResp: "",
    phoneDistResp: "",
    ClienteId: 0,
    products: []
  };

  public UserRegister: IUserRegisterDatos = {
    tipoDocID: 0,
    cuit: "",
    email: "",
    businessName: "",
    dni: "",
    street: "",
    provinciaID: 0,
    paisID: 0,
    localidadID: 0,
    localidadDesc: "",
    usuarioId:0
  }

  public crearUsuario: boolean = false;

  public Combos: any = [];
  public Descuentos: Descuento[] = [];
  public DescuentosAgrupados: any[] = [];

  public GruposReferencia: any[] = [];
  public GruposReferenciaAgrupados: any[] = [];

  public UpdateCartTimeout: any[] = [];
  public updateando: boolean = false;

  public $buttonContinuarCheckoutDisabled = new BehaviorSubject<boolean>(false);
  private TipoRetiroBehavior = new BehaviorSubject<number>(0);
  TipoRetiro$ = this.TipoRetiroBehavior.asObservable();

  constructor(private storageService: StorageService, private http: HttpClient) {
    this.init();
  }

  // public loadCart(data?) {
  //   if (data) {
  //     this.updateCart(data);
  //     return;
  //   }
  //   this.http.post<ICartResponse>(API.BASE + API.CART_LIST, {}).subscribe((data: any) => {
  //     this.updateCart(data);
  //   }, (error) => {
  //     console.log(error);
  //   });
  // }

  public loadCart(data?, oParam?: ICartParam) {
    console.dir(`parametro updatecart: ${JSON.stringify(oParam)}`)
    if (data) {
      this.updateCart(data);
      return;
    }

    this.http.post<ICartResponse>(API.BASE + API.CART_LIST, oParam).subscribe((data: any) => {
      if (oParam) {
        const carritoLS = this.getCartFromLocalStoarge();

        data.Products = data.Products.map((x: ICart) => {
          const cantidad = carritoLS.find(j => x.ProductId == j.ProductId).counter
          if (cantidad)
            x.Quantity = cantidad;
          return x
        });
      }
      this.updateCart(data);
    }, (error) => {
      console.log(error);
    });

  }

  public updateCart(data) {
    this.cart = [];
    let cantBidones = 0;

    data.Products.forEach((product: ICart) => {

      if (product.IncluyeEnvase) {
        cantBidones += product.Quantity;
      }
      this.cart.push({ ProductId: product.ProductId, counter: product.Quantity, precio: product.Price, IncluyeEnvase: product.IncluyeEnvase });
    });

    if (cantBidones > 0) {
      // this.cart.push({
      //   ProductId: 946,
      //   counter: cantBidones,
      //   precio: 1
      // });
    }

    this.updateStorage();
  }



  public loadDiscounts() {
    this.Descuentos = [];

    this.http.post<IGetDiscountResponse>(API.BASE + API.PRODUCT_DISCOUNTS, {}).subscribe((data: IGetDiscountResponse) => {
      this.Descuentos = data.Descuentos;
      this.agruparDescuentos();
    }, (error) => {
      console.log("ERROR", error);
    });
  }

  public loadReferenceGroups() {
    this.GruposReferencia = [];

    this.http.post<ReferenceGroupResponse>(API.BASE + API.PRODUCT_REFERENCEGROUP, {}).subscribe((data: ReferenceGroupResponse) => {
      this.GruposReferencia = data.GrupoReferencia;
      this.agruparGruposReferencia();
    }, (error) => {
      console.log("ERROR", error);
    });
  }

  public async init() {
    this.cart = [];
    this.list.next(this.cart);
    this.updateStorage();
    // const List: any = await this.storageService.getItem('cart_list', []);
    // this.cart = List.data;

    this.Total = 0;
    this.TotalBehavior.next(this.Total);

    this.CantTotal = 0;
    this.CantBehavior.next(this.CantTotal);

    this.paso = 0;
    this.pasoBehavior.next(this.paso);

    //this.loadReferenceGroups();
    this.TipoRetiroBehavior.next(null);
  }

  public updateStorage(): void {
    this.storageService.setItem('cart_list', this.cart);
  }

  public updateLocalStorage(): void {
    // localStorage.setItem('cart_list', JSON.stringify(this.cart));
  }

  public getListNotCheckedContainer(): Promise<any> {
    return this.storageService.getItem('containers_list', []);
  }

  public addToNotCheckedContainer(ProductId: number, valor: boolean): void {
    const index = this.notIncludesContainer.indexOf(ProductId, 0);

    if (valor) {
      if (index > -1) {
        this.notIncludesContainer.splice(index, 1);
      }
    } else {
      if (index == -1) {
        this.notIncludesContainer.push(ProductId);
      }
    }

    this.storageService.setItem('containers_list', this.notIncludesContainer);
  }

  public cartStatus(): Observable<any> {
    return this.http.post(API.BASE + API.CART_STATUS, {});
  }

  public getPayment(importe: number,usuarioId:number): Observable<any> {
    const pedidosEncParam = {
      RetiroId: this.RetiroId,
      TipoDeRetiroId: this.TipoDeRetiroId,
      OpcionDePagoId: this.OpcionesPagoId,
      CodCompIdFac: this.CodCompId,
      SucursalIdFac: this.Sucursal,
      NroComp: this.NroComp,
      DireccionEntrega: this.DireccionEntrega,
      Observaciones: this.Observaciones,
      Total: importe,
      Impuestos: this.TotalImpuestos,
      SubTotal: this.SubTotal,
      TransporteId: this.TransporteId,
      TransporteDesc: this.TransporteDesc,
      Productos: this.cart,
      OpcionDePagoDesc: this.OpcionDePagoDesc,
      UsuarioId:usuarioId
    }
    return this.http.post(API.BASE + API.CART_GETPAYMENT, { Total: 0, PedidoParam: pedidosEncParam });
  }

  public checkPayment(preferenceId: string): Observable<any> {
    return this.http.post(API.BASE + API.CART_CHECKPAYMENT, { PreferenceId: preferenceId });
  }

  public getTaxes(): Observable<any> {
    return this.http.post(API.BASE + API.CART_TAXES, { TipoDeRetiroId: this.TipoDeRetiroId, RetiroId: this.RetiroId, CuponId: this.CuponId });
  }

  public saveInvoice(PaymentId: number, Total: number, EsPedido: boolean): Observable<any> {
    return this.http.post(API.BASE + API.CART_INVOICE, {
      PaymentId: PaymentId,
      Total: Total,
      TipoDeRetiroId: this.TipoDeRetiroId,
      RetiroId: this.RetiroId,
      EsPedido: EsPedido,
      CodCompId: this.CodCompId,
      Sucursal: this.Sucursal,
      NroComp: this.NroComp,
      CuponId: this.CuponId,
      TransporteId: this.TransporteId,
      TransporteDesc: this.TransporteDesc,
      OpcionDePagoId: this.OpcionesPagoId
    });
  }

  public RegistrarUsuarioSinLogin( ): Observable<any> {
    return this.http.post(API.BASE + API.REGISTER_WITHOUTLOGIN, {
      CUIT: this.UserRegister.cuit,
      Email: this.UserRegister.email,
      RazonSocial: this.UserRegister.businessName,
      ProvinciaID: this.UserRegister.provinciaID,
      PaisID: this.UserRegister.paisID,
      Dni: this.UserRegister.dni,
      TipoDocID: this.UserRegister.tipoDocID,
      Calle: this.UserRegister.street,
      NroDocumento: this.UserRegister.dni,
      LocalidadID: this.UserRegister.localidadID,
      LocalidadDesc: this.UserRegister.localidadDesc
    });
  }

  /************************************************/
  /* List                                         */
  /************************************************/

  public addToList(ProductId: number, counter: number, precio: number,deprecatedIsLoggedIn:boolean, incluyeEnvase: boolean = false): void {
    this.storageService.getItem('session').then(response => {
      const isLoggedIn = [response.data].filter(x => x).length > 0
      const index: number = this.cart.findIndex((item) => item.ProductId == ProductId);
      if (index === -1) {
        this.cart.push({ ProductId: ProductId, counter: counter, precio: precio });
      } else {
        this.cart[index].counter = counter;
        this.cart[index].precio = precio;
      }

      this.updateStorage();
      this.updateLocalStorage();

      this.list.next(this.cart);
      this.CalculateTotal();
      this.CalculateCantTotal();
      this.limpiarCantCero();
      clearTimeout(this.UpdateCartTimeout[ProductId]);

      if (!isLoggedIn) {
        return;
      }
      
      this.updateando = true;

      const cartUpdateParam: ICartParam = {
        productId: ProductId,
        checkEnvase: incluyeEnvase,
        productsId: [ProductId],
        quantity: counter,
        usuarioId: 0
      }
      this.UpdateCartTimeout[ProductId] = setTimeout(() => {
        this.http.post<any>(API.BASE + API.CART_UPDATE, cartUpdateParam).subscribe((data: any) => {
          this.loadCart(data);
          this.updateando = false;
        });
      }, 500);
    })
  }

  public addToCartWebNoLogin(ProductId: number, counter: number, precio: number, usuarioId: number): void{
    const cartUpdateParam: ICartParam = {
      productId: ProductId,
      checkEnvase: false,
      productsId: [ProductId],
      quantity: counter,
      usuarioId: usuarioId
    }
    this.UpdateCartTimeout[ProductId] = setTimeout(() => {
      this.http.post<any>(API.BASE + API.CART_UPDATE, cartUpdateParam).subscribe((data: any) => {
        // console.log(data);
      });
    }, 500);
  }

  public PriceRefresh(ProductId: number, precio: number): void {
    const index: number = this.cart.findIndex((item) => item.ProductId == ProductId);
    if (index !== -1) {

      if (this.cart[index].precioOriginal == undefined) {
        this.cart[index].precioOriginal = this.cart[index].precio;
        this.cart[index].precio = precio;
      } else {
        this.cart[index].precio = precio;
        // delete this.cart[index].precioOriginal;
      }

    }

    this.updateStorage();
    this.list.next(this.cart);
    this.CalculateTotal();
  }

  public CalculateTotal() {
    let sum: number = 0;
    //valido si el cupon sigue vigente y si no lo esta lo elimino, con un snackbar avisando!
    // this.validateCupon('', this.CuponId).subscribe((data: any) => {
    //   if (data.HayError) {
    //     this.CuponId = null;
    //     this.Porcentaje = null;
    //     this.storageService.setItem("CuponId", null);
    //     this.storageService.setItem("Porcentaje", null);
    //     this.storageService.setItem("Codigo", null);
    //   }
    // });

    // this.cart.forEach(x => sum += (x.precio * x.counter));
    this.cart.forEach(x => sum += this.CuponId != null ? ((x.precio - (x.precio * this.Porcentaje / 100)) * x.counter) : (x.precio * x.counter));
    this.Total = sum;
    this.TotalBehavior.next(this.Total);
  }

  public CalculateCantTotal() {
    let sum: number = 0
    this.cart.forEach(x => sum += (x.counter));
    this.CantTotal = sum;
    this.CantBehavior.next(this.CantTotal);
  }

  public setearTipoRetiro(retiroID: number) {
    this.TipoDeRetiroId = retiroID;
    this.TipoRetiroBehavior.next(retiroID);
  }

  public SetearPaso(pasoNumero: number): void {
    this.paso = pasoNumero;
    this.pasoBehavior.next(this.paso);
  }

  public getTotales(): number {
    let sum: number = 0
    let sumDiscount: number = 0;
    //valido si el cupon sigue vigente y si no lo esta lo elimino, con un snackbar avisando!
    // this.validateCupon('', this.CuponId).subscribe((data: any) => {
    //   if (data.HayError) {
    //     this.CuponId = null;
    //     this.Porcentaje = null;
    //     this.storageService.setItem("CuponId", null);
    //     this.storageService.setItem("Porcentaje", null);
    //     this.storageService.setItem("Codigo", null);
    //   }
    // });

    if (this.cart.length > 0) {
      this.cart.forEach(x => sum += this.CuponId != null ? ((x.precio - (x.precio * this.Porcentaje / 100)) * x.counter) : (x.precio * x.counter));
    }

    if (this.Descuentos.length > 0) {
      for (let i = 0; this.cart.length > i; i++) {
        let discount = this.Descuentos.find((obj) => {
          return obj.ArticuloID == this.cart[i].ProductId;
        });

        if (discount) {
          let discountCal = this.cart[i].precio * (discount.Porcentaje / 100)
          sumDiscount += discountCal;
        }

      }
    }

    this.Total = sum;
    this.TotalBehavior.next(this.Total);

    return sum;
  }

  public getCantTotales(): number {
    let sum: number = 0;
    if (this.cart.length > 0) {
      this.cart.forEach(x => sum += (x.counter));
      this.CantTotal = sum;
      this.CantBehavior.next(this.CantTotal);
    }
    return sum;
  }

  public removeFromListContainer(ProductId: number): void {
    const index = this.notIncludesContainer.indexOf(ProductId, 0);

    if (index > -1) {
      this.notIncludesContainer.splice(index, 1);
    }

    this.storageService.setItem('containers_list', this.notIncludesContainer);
  }

  public removeFromList(ProductId: number, incluyeEnvase: boolean = false): void {
    const index: number = this.cart.findIndex((item) => item.ProductId == ProductId);

    this.storageService.getItem('session').then(response => {
      const isLoggedIn = [response.data].filter(x => x).length > 0

      if (index !== -1) {
        this.cart.splice(index, 1);
      }
      this.updateStorage();
      this.updateLocalStorage();

      this.list.next(this.cart);

      this.CalculateTotal();
      this.CalculateCantTotal();
      this.limpiarCantCero();

      const cartUpdateParam: ICartParam = {
        productId: ProductId,
        quantity: 0,
        checkEnvase: false,
        productsId: [],
        usuarioId: 0
      }

      this.http.post<any>(API.BASE + API.CART_UPDATE, cartUpdateParam).subscribe((data) => {
        if (data != null) {
          this.Descuentos = data.Discounts;
          this.agruparDescuentos();


          if(this.GruposReferencia == undefined){return;} 
          this.GruposReferencia.forEach((item) => {
            if (item.ArticuloID === ProductId) this.loadReferenceGroups();
          });
        }
      });

    })
  }

  public limpiarCantCero() {
    var filtro = this.cart.filter(item => item.counter != 0);
    this.cart = filtro;
  }

  public agruparGruposReferencia() {
    if (this.GruposReferencia && this.GruposReferencia.length > 0) {
      let arr: any[] = [];

      this.GruposReferencia.forEach((ele) => {
        let objCart = this.cart.find((cartItem) => {
          return cartItem.ProductId == ele.ArticuloID;
        });

        let index = arr.findIndex((element) => {
          return element.NombreGrupoRef == ele.NombreGrupoRef;
        });

        if (index == -1) {
          arr.push({
            NombreGrupoRef: ele.NombreGrupoRef,
            IdsArticulo: [ele.ArticuloID],
            Cantidad: 0, //objCart.counter,
            CantMinima: ele.CantMinima
          });
        } else {
          let item = arr[index];
          let indexArticuloId = item.IdsArticulo.findIndex((e) => e == ele.ArticuloID);

          if (indexArticuloId == -1) {
            item.IdsArticulo.push(ele.ArticuloID);
            item.Cantidad += objCart.counter;
          }
        }
      })

      this.GruposReferenciaAgrupados = arr;
    } else
      this.GruposReferenciaAgrupados = [];

  }

  public agruparDescuentos() {
    if (this.Descuentos && this.Descuentos.length > 0) {
      let arr: any[] = [];

      //tengo una lista de articulos del carrito
      this.Descuentos.forEach((ele) => {
        //tengo que sumar cantidades en el carrito, l osaco por articulo id
        let cantCart = this.cart.find((cartItem) => {
          return cartItem.ProductId == ele.ArticuloID;
        });
        //tengo que concatenar ids si ya existe el codigo precio asociado en el array
        //TODO: Ver como hago cuando tenga varias cantidades por un descuento
        if (cantCart != undefined) {
          let index = arr.findIndex((element) => {
            return element.CodigoAsociado == ele.PrecioAsociado; //&& element.CantidadDescuento == ele.Cantidad;
          });

          if (index == -1) {
            //lo agrego
            arr.push({
              CodigoAsociado: ele.PrecioAsociado,
              CantidadCarrito: cantCart == undefined ? 0 : cantCart.counter,
              CantidadDescuento: [ele.Cantidad],
              Porcentaje: [ele.Porcentaje],
              IdsArticulo: [ele.ArticuloID]
            });
          } else {
            //update el ya existente
            let item = arr[index];
            //me fijo si item CantidadDescuento esta el valor de ele.Cantidad, si esta no pasa nada sino hago push de cantidad y porcentaje asociado a ele
            let indexCant = item.CantidadDescuento.findIndex((cantEle) => cantEle == ele.Cantidad);
            let indexArticuloId = item.IdsArticulo.findIndex((e) => e == ele.ArticuloID);

            if (indexCant == -1) {
              item.CantidadDescuento.push(ele.Cantidad);
              item.Porcentaje.push(ele.Porcentaje);
            }

            if (indexArticuloId == -1) {
              item.IdsArticulo.push(ele.ArticuloID);
              item.CantidadCarrito += (cantCart.counter == undefined ? 0 : cantCart.counter);
            }
          }
        }
      });

      this.DescuentosAgrupados = arr;
    }
  }

  public getCartItemCounter(ProductId: number): number {
    const index: number = this.cart.findIndex((item) => item.ProductId == ProductId);
    if (index === -1) {
      return 0;
    } else {
      return Math.floor(Math.max(0, this.cart[index].counter));
    }
  }

  public isAdded(ProductId: number): boolean {
    const index: number = this.cart.findIndex((item) => item.ProductId == ProductId);
    return (index !== -1);
  }

  public clearList(): void {
    // this.Total = 0;
    // this.CantTotal = 0;
    // this.list.next(this.cart);
    // this.TotalBehavior.next(this.Total);
    // this.CantBehavior.next(this.CantTotal);
    this.RetiroId = 0;
    this.cart = [];
    this.updateStorage();
    this.Observaciones = '';
    this.storageService.setItem('RetiroPedido', '');
    this.storageService.setItem('RetiroNombre', '');
    this.storageService.setItem('RetiroDireccion', '');
    this.storageService.setItem('Observaciones', '');
    this.storageService.setItem('TipoDeRetiroId', '');
    this.storageService.setItem('PreferenceId', '');
    this.storageService.setItem('OpcionesPagoId', null);
    this.storageService.setItem('TransporteID', null);
    this.storageService.setItem('TransporteDesc', null);
    this.storageService.setItem('TransporteDireccion', null);
    this.storageService.setItem('OpcionDePagoDesc', null);

    this.storageService.setItem('CuponId', null);
    this.storageService.setItem('Porcentaje', null);
    this.storageService.setItem('Codigo', null);

    //TODO limpiar proyecto
    this.Proyecto = undefined;
  }

  public getList(): any {
    return this.storageService.getItem('cart_list', []);
  }

  public getTipoRetiroId(): any {
    return this.storageService.getItem('TipoRetiroId', null);
  }

  public getTotal(): void {
    this.TotalBehavior.next(this.Total);
  }

  public getCantTotal(): void {
    this.CantBehavior.next(this.CantTotal);
  }

  public setTotal(ProductId: number, importe: number): void {
    this.Total += importe;
    this.TotalBehavior.next(this.Total);
  }


  public getCartFromDb(param?: ICartParam): Observable<ICartResponse> {
    return new Observable((observer) => {
      this.http.post<ICartResponse>(API.BASE + API.CART_LIST, param).subscribe((asyncData: ICartResponse) => {
        observer.next(asyncData);
        observer.complete();
      });
    });
  }

  public getCartFromLocalStoarge(): any {
    const carritoStorage = localStorage.getItem("cart_list")
    const carritoParsed = JSON.parse(carritoStorage)
    return carritoParsed
  }

  public getHasPendingMP(): Observable<any> {
    return new Observable((observer) => {
      this.http.post<ICartResponse>(API.BASE + API.CART_PENDING_MP, {})
        .subscribe((hasPending: any) => {
          observer.next(hasPending);
          observer.complete();
        });
    });
  }

  public getDiscountsFromDb(): Observable<IGetDiscountResponse> {
    return new Observable((observer) => {
      this.http.post<IGetDiscountResponse>(API.BASE + API.PRODUCT_DISCOUNTS, {}).subscribe((asyncData: IGetDiscountResponse) => {
        observer.next(asyncData);
        observer.complete();
      });
    });
  }

  public getReferenceGroupsFromDb(): Observable<ReferenceGroupResponse> {
    return new Observable((observer) => {
      this.http.post<ReferenceGroupResponse>(API.BASE + API.PRODUCT_REFERENCEGROUP, {}).subscribe((asyncData: ReferenceGroupResponse) => {
        observer.next(asyncData);
        observer.complete();
      });
    });
  }

  public ConfirmCart(mercadopago: boolean): Observable<IErrorData> {
    var request = this.resetRequest();
    request.MercadoPago = mercadopago;
    return this.http.post<IErrorData>(API.BASE + API.CART_CONFIRM, request)
  }

  public savePedido(PaymentId: number, Total: number, EsPedido: boolean, mercadopago: boolean, usuarioId:number): Observable<any> {
    return this.http.post(API.BASE + API.CART_PEDIDO, {
      PaymentId: this.OpcionesPagoId == 1 ? PaymentId : null,
      Total: Total,
      TipoDeRetiroId: this.TipoDeRetiroId,
      RetiroId: this.RetiroId,
      EsPedido: EsPedido,
      CuponId: this.CuponId,
      PreferenceID: this.OpcionesPagoId == 1 ? this.PreferenceId : null,
      Observations: this.Observaciones,
      EsProyecto: false,
      Proyecto: this.Proyecto,
      MercadoPago: this.OpcionesPagoId == 1 ? mercadopago : false,
      TransporteId: this.TransporteId,
      TransporteDesc: this.TransporteDesc,
      OpcionDePagoId: this.OpcionesPagoId,
      OpcionDePagoDesc: this.OpcionDePagoDesc,
      DireccionEntrega: this.DireccionEntrega,
      Impuestos: this.TotalImpuestos,
      SubTotal: this.SubTotal,
      UsuarioId: usuarioId,
      cart: this.cart
      
    });
  }

  public resetRequest(): any {
    return {
      RetiroID: this.RetiroId,
      TipodeRetiroID: this.TipoDeRetiroId,
      Observations: this.Observaciones,
      EsProyecto: this.Proyecto !== undefined ? true : false,
      Proyecto: this.Proyecto,
      PreferenceID: this.PreferenceId,
      CodCompId: this.CodCompId,
      Sucursal: this.Sucursal,
      NroComp: this.NroComp,
      CodCompIdPed: this.CodCompIdPed,
      SucursalPed: this.SucursalPed,
      NroCompPed: this.NroCompPed,
      CuponId: this.CuponId
    }
  }

  public getCombos(): any {
    return this.http.post<any>(API.BASE + API.CART_COMBOS, {});
    // .subscribe((asyncData) => {

    //   this.Combos = asyncData;

    // });
  }

  public getProject() {
    return {
      "Name": this.Proyecto.name,
      "FechaLimite": this.Proyecto.fechaLimite,
      "FechaDecision": this.Proyecto.fechaDecision,
      "FechaProvision": this.Proyecto.fechaProvision,
      "NameFinal": this.Proyecto.nameFinal,
      "NameContacto": this.Proyecto.nameContacto,
      "EmailCliFinal": this.Proyecto.emailCliFinal,
      "PresupuestoId": this.Proyecto.presupuestoId,
      "TipoProyectoId": this.Proyecto.tipoProyectoId,
      "CategoriaId": this.Proyecto.categoriaId,
      "ContratacionId": this.Proyecto.contratacionId,
      "ZonaId": this.Proyecto.zonaId,
      "NameFirma": this.Proyecto.nameFirma,
      "NameResp": this.Proyecto.nameResp,
      "EmailResp": this.Proyecto.emailResp,
      "PhoneResp": this.Proyecto.phoneResp,
      "PhoneCliFinal": this.Proyecto.phoneCliFinal,
      "DespProyecto": this.Proyecto.despProyecto,
      "Equipamiento": this.Proyecto.equipamiento,
      "Competidores": this.Proyecto.competidores,
      "Observaciones": this.Proyecto.observaciones,
      "NameDist": this.Proyecto.nameDist,
      "NameDistResp": this.Proyecto.nameDistResp,
      "EmailDistResp": this.Proyecto.emailDistResp,
      "PhoneDistResp": this.Proyecto.phoneDistResp,
      "Products": this.Proyecto.products,
      "ClienteId": this.Proyecto.ClienteId
    }
  }

  public setearProyecto(): Observable<IErrorData> {
    return this.http.post<IErrorData>(API.BASE + API.SAVE_PROJECT, this.getProject());
  }

  public validateCupon(codigo: string, cuponId: number): Observable<any> {
    return this.http.post<any>(API.BASE + API.CART_VALIDATECOUPON, {
      "CouponCode": codigo,
      "CuponId": cuponId
    });
  }

  public validateCallback(param: ICartValidateCallback): Observable<any> {
    return this.http.post<any>(API.BASE + API.CART_VALIDATECALLBACK, param);
  }

  public LimpiarProductoDeGrupoDeReferencia(productId: number) {


    //this.GruposReferencia = this.GruposReferencia.filter(obj => {return obj !== productId});

  }

}
