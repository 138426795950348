import { Injectable } from '@angular/core';
import { Subject,BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingScreenService {

  isLoading$ = new BehaviorSubject<boolean>(true);

  constructor() { }

  show(){
    this.isLoading$.next(true)
  }

  hide(){
    this.isLoading$.next(false)
  }
  hideVanilla(){
    const loaderOverlay = document.getElementById('loader-overlay')
    loaderOverlay.style.display = 'none'
  }
}
