import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { API } from '../api.enum';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { StorageService } from '../storage/storage.service';
import { IProductsResponse, IProduct } from 'src/app/interfaces/product.interface';

@Injectable({
  providedIn: 'root'
})

export class FavoritesService {

  public list: any = [];

  private list2 = new BehaviorSubject<any[]>([]);
  list2$ = this.list2.asObservable();

  constructor(private storageService: StorageService, private http: HttpClient) {
    this.init();
  }

  /**
 * init
 */
  public async init() {
    this.clearList();
  }


  /**
   * updateStorage
   */
  public updateStorage(): void {
    this.storageService.setItem('favorite_list', this.list);
  }


  public loadFavorites() {
    this.list = [];
    this.http.post<IProductsResponse>(API.BASE + API.FAVORITE_LIST, {}).subscribe((data: any) => {
      data.Products.forEach((product: IProduct) => {
        this.list.push({ id: product.ProductId });
      });
      this.updateStorage();
    });
  }

  /************************************************/
  /* List                                         */
  /************************************************/

  /**
   * addToList
   * @param id
   */
  public addToList(id: number): void {
    const index: number = this.list.findIndex((item: any) => item.id === id);
    if (index === -1) {
      this.list.push({ id: id });
      this.http.post(API.BASE + API.FAVORITE_UPDATE, { ProductId: id }).subscribe();
    }
    this.updateStorage();
  }

  /**
   * removeFromList
   * @param id 
   */
  public removeFromList(id: number): void {
    const index: number = this.list.findIndex((item: any) => item.id === id);
    if (index !== -1) {
      this.list.splice(index, 1);
      this.http.post(API.BASE + API.FAVORITE_UPDATE, { ProductId: id }).subscribe();
    }
    this.updateStorage();
  }

  //TODO hacerlo arriba en removeFromList
  public removeFromFavs(ProductId: number): void {
    const index: number = this.list.findIndex((item: any) => item.id === ProductId);
    if (index !== -1) {
      this.list.splice(index, 1);
    }

    this.updateStorage();

    this.list2.next(this.list);

    this.http.post<any>(API.BASE + API.FAVORITE_UPDATE, {
      ProductId: ProductId
    }).subscribe(
      (data) => {
        //se comenta por error pasado por GRACE 08/04/2020
        // this.list = data.Products;
      }
    );
  }

  /**
   * isFavorite
   * @param id 
   */
  public isFavorite(id: number): boolean {
    const index: number = this.list.findIndex((item: any) => item.id === id);

    //console.log(this.list);
    //console.log("isFavorite " + id + " " + index);

    return (index !== -1);
  }

  /**
   * clearList
   */
  public clearList(): void {
    this.list = [];
    this.list2.next(this.list);
    this.updateStorage();
  }

}
